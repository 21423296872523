import React from 'react'
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import icon from "../images/404.png"
import '../App.css';
const Error = () =>
{
    const mystyle = {
        color: "white",
        textAlign:"center",
        padding: "10px",
        fontFamily: "Arial"
      };
    return (
            <>
            <div className='' style ={mystyle}>
            <h2>Hello there !</h2>
            <h2>Winter is Coming...Dude!</h2>
           <h3><a href="/">Back To Home !</a></h3> 
                <img src={icon} />
            </div>
            </>
    )
}

export default Error;