import React from 'react';
import { Spin } from 'antd'

const Loader = () => {
    return (
      <div class="loader">
            <Spin />
    </div>
  )
}

export default Loader