import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { Layout, Typography, Space } from 'antd';
import {Error, Navbar, Exchanges, Homepage, Cryptocurrencies, CryptoNews, CryptoDetails, Projects} from './components';

import './App.css';

const App = () => {
  return (
    <div className='app'>

      <div className=''>
        <Navbar />
      </div>

      <div className='main'>
        <Layout>
          <div className='routes'>
            <Switch>
              <Route exact path='/'>
               <Homepage/>
              </Route>
              <Route exact path='/exchanges'>
               <Exchanges/>
              </Route>
              <Route exact path='/cryptocurrencies'>
               <Cryptocurrencies/>
              </Route>
              <Route exact path='/crypto/:coinId'>
               <CryptoDetails/>
              </Route>
              <Route exact path='/crypto-news'>
                <CryptoNews />
              </Route>
              <Route exact path='/projects'>
                <Projects />
              </Route>
              <Route path='*'><Error/></Route>
            </Switch>
          </div>
        </Layout>
        
      <div className='footer'>
          <p>🧑‍💻 & Crafted with 💚 by 
              <a href="https://www.linkedin.com/in/sandeepningwal/"
              target="_blank"> SANDEEP NINGWAL.</a> </p>
      <Typography.Title level={5}>
        Cryptoranking 2023 &copy; All rights reserved || Credits to : coinranking.com and bing.com
        </Typography.Title>
        <Space>
            <Link to='/'>Home</Link>
            <Link to='/cryptocurrencies'>Crypto</Link>
          <Link to='/crypto-news'>CryptoNews</Link>
        </Space>
      </div>
      </div>
    </div>
  );
}

export default App