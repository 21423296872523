import React from 'react'
// import { Button } from 'antd';
// import { Link } from 'react-router-dom';
import '../App.css';
const Projects = () =>
{
    const mystyle = {
        color: "white",
        textAlign:"center",
        padding: "10px",
        fontFamily: "Arial"
      };
    return (
            <>
            <div className='' style ={mystyle}>
                <h2>Other Projects!</h2>
                 <ul>
                <li><a href = "https://foodmart.cryptoranking.net/" target='__blank'>1.FoodMart Android App</a></li>
                <li><a href = "https://securemessenger.cryptoranking.net/"  target='__blank'>2.Secure Messenger Android App</a></li>
                </ul>
            {/* <h2>Winter is Coming...Dude!</h2>
           <h3><a href="/">Back To Home !</a></h3>  */}
            </div>
            </>
    )
}

export default Projects;