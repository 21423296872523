import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

//set Header
const cryptoApiHeaders = {
    'X-RapidAPI-Key': 'f1cc1a9ba5msh95ae600328fe196p1d5e09jsncb62a07f0b9f',
    'X-RapidAPI-Host': 'coinranking1.p.rapidapi.com'
}
//
const baseUrl = 'https://coinranking1.p.rapidapi.com';

const createRequest = (url) => ({ url, headers: cryptoApiHeaders });

export const cryptoApi = createApi({
    reducerPath: 'cryptoApi',
    baseQuery: fetchBaseQuery({ baseUrl }),
    endpoints: (builder) => ({
        getCryptos: builder.query({
            query: (count) => createRequest(`/coins?limit=${count}`),
        }),
        getExchanges: builder.query({
            query: () => createRequest(`/exchanges`),
        }),
        getCryptoDetails: builder.query({
            query: (coinId) => createRequest(`/coin/${coinId}`),
        }),
        getCryptoHistory: builder.query({
            //query: (coinId, timePeriod) => createRequest(`/coin/${coinId}/history/${timePeriod}`),
            query: ({coinId, timePeriod}) => createRequest(`/coin/${coinId}/history?timePeriod=${timePeriod}`),
        })
    })
});

export const {
    useGetCryptosQuery,
    useGetCryptoDetailsQuery,
    useGetCryptoHistoryQuery,
    useGetExchangesQuery
} = cryptoApi;

// const options = {
//     method: 'GET',
//     url: 'https://coinranking1.p.rapidapi.com/coins',
//     params: {
//       referenceCurrencyUuid: 'yhjMzLPhuIDl',
//       timePeriod: '24h',
//       'tiers[0]': '1',
//       orderBy: 'marketCap',
//       orderDirection: 'desc',
//       limit: '50',
//       offset: '0'
//     },
//     headers: {
//       'X-RapidAPI-Key': 'f1cc1a9ba5msh95ae600328fe196p1d5e09jsncb62a07f0b9f',
//       'X-RapidAPI-Host': 'coinranking1.p.rapidapi.com'
//     }
//   };