import React,{useState, useEffect} from 'react';
import { Button, Menu, Typography,Avatar} from 'antd';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { GithubOutlined, HomeOutlined, MoneyCollectOutlined, BulbOutlined, FundOutlined, MenuOutlined, AntCloudOutlined, BookOutlined } from '@ant-design/icons';
import icon from "../images/cryptoranking.png"
const Navbar = () => {
    // const [showMediaIcons, setShowMediaIcons] = useState(false);
    const [activeMenu, setActiveMenu] = useState(true);
    const [screenSize, setScreenSize] = useState(null);
    useEffect(() => {
        const handleResize = () => setScreenSize(window.innerWidth);
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    
    useEffect(() => {
        if (screenSize < 768) {
            setActiveMenu(false);
        }
        else {
            setActiveMenu(true);
        }
    },[screenSize])
    return (
        
<>
      <nav className="main-nav">
        {/* 1st logo part  */}
        <div className="logo">
            <a href= "/"><img  src="/cryptoranking.png" alt="Cryptoranking" /></a>
        </div>
                {/* 2nd menu part  */}
    <Button className="menu-control-container" onClick={() => setActiveMenu(!activeMenu)}>
                    <MenuOutlined/>
                </Button>
                
        <div className={activeMenu ? "menu-link mobile-menu-link" : "menu-link"   }>
                    <ul>
              <li>
            Hello there ! <img src="https://raw.githubusercontent.com/sningwal/sningwal/main/wave.gif" alt ="👋" width="30px"/>
            </li>     
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/cryptocurrencies">Cryptocurrencies</Link>
            </li>
            <li>
            <Link to="/crypto-news">Crypto News</Link>
            </li>
            <li>
              <a exact href="https://github.com/sningwal/cryptoranking" target="_blank"> <GithubOutlined /></a>
            </li>
            <li>
                 <Link to="/projects"> <BookOutlined />Other Projects</Link>
            </li>
        </ul>
          </div>   
      </nav>
    </>
    );
}
export default Navbar